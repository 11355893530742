<template>
  <div class="home">
    <div class="container">
      <div class="top-content ">
        <!-- <img src="../assets/img/home-title.png" alt=""> -->
        <!-- <div class="d-flex justify-content-center search-button-container f-bold f-14">
          <div class="button cursor-p">
            免费核名
          </div>
          <div data-event-name="企业服务首页_查商标按钮_点击" class="button cursor-p active">
            查商标
          </div>

        </div> -->
        <div class="search_kuang">

          <div @click="isChange(0)" :class="{button:true,isChoose:isOn===0}">
            免费核名
          </div>
          <div @click="isChange(1)" :class="{button:true,isChoose:isOn===1}">
            查商标
          </div>
          <div class="top_title">
            一站式创新创业服务平台
          </div>
          <div class="jiantou"
            :style="{left:isOn===1?'55%':'42%',transform:isOn===1?'translateX(-2px)':'translateX(2px)' }"></div>
          <div @click="showCity()" class="isCity" :style="{display: isOn===0?'block':'none'}">
            <span>{{newCity}}</span>
            <svg v-show="!chooseCity" t="1734073727304" class="icon" viewBox="0 0 1024 1024" version="1.1"
              xmlns="http://www.w3.org/2000/svg" p-id="4272" width="12" height="12">
              <path d="M325.456471 862.280661" fill="#ff8b02" p-id="4273"></path>
              <path d="M882.057788 862.280661" fill="#ff8b02" p-id="4274"></path>
              <path d="M236.028491 877.160382" fill="#ff8b02" p-id="4275"></path>
              <path d="M960.132455 877.160382" fill="#ff8b02" p-id="4276"></path>
              <path d="M63.683483 788.736998" fill="#ff8b02" p-id="4277"></path>
              <path d="M958.469023 788.736998" fill="#ff8b02" p-id="4278"></path>
              <path d="M64.77753 858.792098" fill="#ff8b02" p-id="4279"></path>
              <path
                d="M163.396533 289.168875c-40.577772 0-66.525252 54.184545-35.441258 85.258218L477.217578 723.704878c20.031716 20.031716 49.823841 20.031716 69.853837 0l349.274345-349.277785c30.304744-30.294423 6.677812-85.258218-34.928639-85.258218L163.396533 289.168875 163.396533 289.168875z"
                fill="#ff8b02" p-id="4280"></path>
              <path d="M959.523505 858.792098" fill="#ff8b02" p-id="4281"></path>
            </svg>
            <svg v-show="chooseCity" t="1734075734885" class="icon" viewBox="0 0 1024 1024" version="1.1"
              xmlns="http://www.w3.org/2000/svg" p-id="5560" id="mx_n_1734075734886" width="12" height="12">
              <path d="M325.456471 862.280661" fill="#ff8b02" p-id="5561"></path>
              <path d="M882.057788 862.280661" fill="#ff8b02" p-id="5562"></path>
              <path d="M236.028491 877.160382" fill="#ff8b02" p-id="5563"></path>
              <path d="M960.132455 877.160382" fill="#ff8b02" p-id="5564"></path>
              <path d="M63.683483 788.736998" fill="#ff8b02" p-id="5565"></path>
              <path d="M958.469023 788.736998" fill="#ff8b02" p-id="5566"></path>
              <path d="M64.77753 858.792098" fill="#ff8b02" p-id="5567"></path>
              <path
                d="M861.417121 738.727375c41.604731 0 65.233383-54.963795 34.928639-85.258218L547.071415 304.191372c-20.029996-20.031716-49.822121-20.031716-69.853837 0L127.955275 653.469157c-31.085714 31.073673-5.136514 85.258218 35.441258 85.258218L861.417121 738.727375 861.417121 738.727375z"
                fill="#ff8b02" p-id="5568"></path>
              <path d="M959.523505 858.792098" fill="#ff8b02" p-id="5569"></path>
            </svg>
          </div>
          <div @click="showInd()" class="isIndustry" :style="{display: isOn===0?'block':'none'}">
            <span>{{newIndustry}}</span>
            <svg v-show="!chooseInd" t="1734073727304" class="icon" viewBox="0 0 1024 1024" version="1.1"
              xmlns="http://www.w3.org/2000/svg" p-id="4272" width="12" height="12">
              <path d="M325.456471 862.280661" fill="#ff8b02" p-id="4273"></path>
              <path d="M882.057788 862.280661" fill="#ff8b02" p-id="4274"></path>
              <path d="M236.028491 877.160382" fill="#ff8b02" p-id="4275"></path>
              <path d="M960.132455 877.160382" fill="#ff8b02" p-id="4276"></path>
              <path d="M63.683483 788.736998" fill="#ff8b02" p-id="4277"></path>
              <path d="M958.469023 788.736998" fill="#ff8b02" p-id="4278"></path>
              <path d="M64.77753 858.792098" fill="#ff8b02" p-id="4279"></path>
              <path
                d="M163.396533 289.168875c-40.577772 0-66.525252 54.184545-35.441258 85.258218L477.217578 723.704878c20.031716 20.031716 49.823841 20.031716 69.853837 0l349.274345-349.277785c30.304744-30.294423 6.677812-85.258218-34.928639-85.258218L163.396533 289.168875 163.396533 289.168875z"
                fill="#ff8b02" p-id="4280"></path>
              <path d="M959.523505 858.792098" fill="#ff8b02" p-id="4281"></path>
            </svg>
            <svg v-show="chooseInd" t="1734075734885" class="icon" viewBox="0 0 1024 1024" version="1.1"
              xmlns="http://www.w3.org/2000/svg" p-id="5560" id="mx_n_1734075734886" width="12" height="12">
              <path d="M325.456471 862.280661" fill="#ff8b02" p-id="5561"></path>
              <path d="M882.057788 862.280661" fill="#ff8b02" p-id="5562"></path>
              <path d="M236.028491 877.160382" fill="#ff8b02" p-id="5563"></path>
              <path d="M960.132455 877.160382" fill="#ff8b02" p-id="5564"></path>
              <path d="M63.683483 788.736998" fill="#ff8b02" p-id="5565"></path>
              <path d="M958.469023 788.736998" fill="#ff8b02" p-id="5566"></path>
              <path d="M64.77753 858.792098" fill="#ff8b02" p-id="5567"></path>
              <path
                d="M861.417121 738.727375c41.604731 0 65.233383-54.963795 34.928639-85.258218L547.071415 304.191372c-20.029996-20.031716-49.822121-20.031716-69.853837 0L127.955275 653.469157c-31.085714 31.073673-5.136514 85.258218 35.441258 85.258218L861.417121 738.727375 861.417121 738.727375z"
                fill="#ff8b02" p-id="5568"></path>
              <path d="M959.523505 858.792098" fill="#ff8b02" p-id="5569"></path>
            </svg>
          </div>
          <div @click="showCom()" class="isCompany" :style="{display: isOn===0?'block':'none'}">
            <span>{{newCompany}}</span>
            <svg v-show="!chooseCom" t="1734073727304" class="icon" viewBox="0 0 1024 1024" version="1.1"
              xmlns="http://www.w3.org/2000/svg" p-id="4272" width="12" height="12">
              <path d="M325.456471 862.280661" fill="#ff8b02" p-id="4273"></path>
              <path d="M882.057788 862.280661" fill="#ff8b02" p-id="4274"></path>
              <path d="M236.028491 877.160382" fill="#ff8b02" p-id="4275"></path>
              <path d="M960.132455 877.160382" fill="#ff8b02" p-id="4276"></path>
              <path d="M63.683483 788.736998" fill="#ff8b02" p-id="4277"></path>
              <path d="M958.469023 788.736998" fill="#ff8b02" p-id="4278"></path>
              <path d="M64.77753 858.792098" fill="#ff8b02" p-id="4279"></path>
              <path
                d="M163.396533 289.168875c-40.577772 0-66.525252 54.184545-35.441258 85.258218L477.217578 723.704878c20.031716 20.031716 49.823841 20.031716 69.853837 0l349.274345-349.277785c30.304744-30.294423 6.677812-85.258218-34.928639-85.258218L163.396533 289.168875 163.396533 289.168875z"
                fill="#ff8b02" p-id="4280"></path>
              <path d="M959.523505 858.792098" fill="#ff8b02" p-id="4281"></path>
            </svg>
            <svg v-show="chooseCom" t="1734075734885" class="icon" viewBox="0 0 1024 1024" version="1.1"
              xmlns="http://www.w3.org/2000/svg" p-id="5560" id="mx_n_1734075734886" width="12" height="12">
              <path d="M325.456471 862.280661" fill="#ff8b02" p-id="5561"></path>
              <path d="M882.057788 862.280661" fill="#ff8b02" p-id="5562"></path>
              <path d="M236.028491 877.160382" fill="#ff8b02" p-id="5563"></path>
              <path d="M960.132455 877.160382" fill="#ff8b02" p-id="5564"></path>
              <path d="M63.683483 788.736998" fill="#ff8b02" p-id="5565"></path>
              <path d="M958.469023 788.736998" fill="#ff8b02" p-id="5566"></path>
              <path d="M64.77753 858.792098" fill="#ff8b02" p-id="5567"></path>
              <path
                d="M861.417121 738.727375c41.604731 0 65.233383-54.963795 34.928639-85.258218L547.071415 304.191372c-20.029996-20.031716-49.822121-20.031716-69.853837 0L127.955275 653.469157c-31.085714 31.073673-5.136514 85.258218 35.441258 85.258218L861.417121 738.727375 861.417121 738.727375z"
                fill="#ff8b02" p-id="5568"></path>
              <path d="M959.523505 858.792098" fill="#ff8b02" p-id="5569"></path>
            </svg>
          </div>
          <div class="search" :style="{display: isOn===0 ? 'flex':'none'}">
            <input class="search_content" v-model="searchInput" placeholder="请输入您检索的字号，如：华为" clearable>
            </input>
            <el-button type="primary" @click="openDialog('公司','核名')">立即核名</el-button>
          </div>
          <div class="search" :style="{display: isOn===0 ? 'none':'flex'}">
            <input class="search_content" v-model="searchInput" placeholder="请输入您检索的商标名称" clearable>
            </input>
            <el-button type="primary" @click="openDialog('商标','商标')">立即查询</el-button>
          </div>
          <div v-show="chooseCity" class="city_content">
            <input type="text">
            <div class="city_code">
              <ul>
                <li @click="isAct(1)" :class="{isActive:onLetter===1}">ABCDE</li>
                <li @click="isAct(2)" :class="{isActive:onLetter===2}">FGHJK</li>
                <li @click="isAct(3)" :class="{isActive:onLetter===3}">LMNPQ</li>
                <li @click="isAct(4)" :class="{isActive:onLetter===4}">RSTWX</li>
                <li @click="isAct(5)" :class="{isActive:onLetter===5}">YZ</li>
              </ul>
            </div>
            <div class="city_menu" @click="handleCity">
              <div v-show="onLetter===1" class='city-container' id="cityContainer">
                <div class="neirong d-flex">
                  <div class="flex-shrink-0 f-14 f-gray-333 m-r-10">A:</div>
                  <div class="flex-grow-1">
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">鞍山</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">安阳</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">安康</span>
                  </div>
                </div>
                <div class="neirong d-flex">
                  <div class="flex-shrink-0 f-14 f-gray-333 m-r-10">B:</div>
                  <div class="flex-grow-1">
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">毫州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">巴中</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">保山</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">北京</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">白山</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">宝鸡</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">北海</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">百色</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">包头</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">巴音郭楞蒙古自治州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">保定</span>
                  </div>
                </div>
                <div class="neirong d-flex">
                  <div class="flex-shrink-0 f-14 f-gray-333 m-r-10">C:</div>
                  <div class="flex-grow-1">
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">承德</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">郴州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">长春</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">楚雄彝族自治州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">滁州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">沧州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">常德</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">赤峰</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">重庆</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">朝阳</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">成都</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">昌吉回族自治州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">常州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">长治</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">长沙</span>
                  </div>
                </div>
                <div class="neirong d-flex">
                  <div class="flex-shrink-0 f-14 f-gray-333 m-r-10">D:</div>
                  <div class="flex-grow-1"><span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">德阳</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">大同</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">大理白族自治州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">达州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">东莞</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">德州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">大连</span>
                  </div>
                </div>
                <div class="neirong d-flex">
                  <div class="flex-shrink-0 f-14 f-gray-333 m-r-10">E:</div>
                  <div class="flex-grow-1">
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">鄂尔多斯</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">恩施土家族苗族自治州</span>
                  </div>
                </div>
              </div>
              <div v-show="onLetter===2" class='city-container' id="cityContainer">
                <div class="neirong d-flex">
                  <div class="flex-shrink-0 f-14 f-gray-333 m-r-10">F:</div>
                  <div class="flex-grow-1">
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">福州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">抚州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">佛山</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">阜阳</span>
                  </div>
                </div>
                <div class="neirong d-flex">
                  <div class="flex-shrink-0 f-14 f-gray-333 m-r-10">G:</div>
                  <div class="flex-grow-1">
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">赣州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">广州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">桂林</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">贵阳</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">贵港</span>
                  </div>
                </div>
                <div class="neirong d-flex">
                  <div class="flex-shrink-0 f-14 f-gray-333 m-r-10">H:</div>
                  <div class="flex-grow-1">
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">淮南</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">黄石</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">杭州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">合肥</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">惠州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">黄冈</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">红河哈尼族彝族自治州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">河源</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">呼伦贝尔</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">菏泽</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">呼和浩特</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">衡阳</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">汉中</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">海口</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">哈密</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">湖州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">邯郸</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">怀化</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">淮安</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">哈尔滨</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">衡水</span>
                  </div>
                </div>
                <div class="neirong d-flex">
                  <div class="flex-shrink-0 f-14 f-gray-333 m-r-10">I:</div>
                  <div class="flex-grow-1">
                  </div>
                </div>
                <div class="neirong d-flex">
                  <div class="flex-shrink-0 f-14 f-gray-333 m-r-10">J:</div>
                  <div class="flex-grow-1">
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">景德镇</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">晋中</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">济南</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">荆州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">吉安</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">九江</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">济宁</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">酒泉</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">焦作</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">揭阳</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">锦州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">晋城</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">荆门</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">江门</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">金华</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">嘉兴</span>
                  </div>
                </div>
              </div>
              <div v-show="onLetter===3" class='city-container' id="cityContainer">
                <div class="neirong d-flex">
                  <div class="flex-shrink-0 f-14 f-gray-333 m-r-10">K:</div>
                  <div class="flex-grow-1">
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">开封</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">昆明</span>
                  </div>
                </div>
                <div class="neirong d-flex">
                  <div class="flex-shrink-0 f-14 f-gray-333 m-r-10">L:</div>
                  <div class="flex-grow-1">
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">拉萨</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">龙岩</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">廊坊</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">柳州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">吕梁</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">漯河</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">聊城</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">洛阳</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">临沂</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">凉山彝族自治州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">来宾</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">泸州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">连云港</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">六安</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">六盘水</span>
                  </div>
                </div>
                <div class="neirong d-flex">
                  <div class="flex-shrink-0 f-14 f-gray-333 m-r-10">M:</div>
                  <div class="flex-grow-1">
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">茂名</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">眉山</span>
                  </div>
                </div>
                <div class="neirong d-flex">
                  <div class="flex-shrink-0 f-14 f-gray-333 m-r-10">N:</div>
                  <div class="flex-grow-1">
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">南昌</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">宁波</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">南通</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">南宁</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">南阳</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">南京</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">南充</span>
                  </div>
                </div>
                <div class="neirong d-flex">
                  <div class="flex-shrink-0 f-14 f-gray-333 m-r-10">O:</div>
                  <div class="flex-grow-1">
                  </div>
                </div>
              </div>
              <div v-show="onLetter===4" class='city-container' id="cityContainer">
                <div class="neirong d-flex">
                  <div class="flex-shrink-0 f-14 f-gray-333 m-r-10">P:</div>
                  <div class="flex-grow-1">
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">普洱</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">濮阳</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">平顶山</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">莆田</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">萍乡</span>
                  </div>
                </div>
                <div class="neirong d-flex">
                  <div class="flex-shrink-0 f-14 f-gray-333 m-r-10">Q:</div>
                  <div class="flex-grow-1">
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">清远</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">泉州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">曲靖</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">钦州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">青岛</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">衢州</span>
                  </div>
                </div>
                <div class="neirong d-flex">
                  <div class="flex-shrink-0 f-14 f-gray-333 m-r-10">R:</div>
                  <div class="flex-grow-1">
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">日照</span>
                  </div>
                </div>
                <div class="neirong d-flex">
                  <div class="flex-shrink-0 f-14 f-gray-333 m-r-10">S:</div>
                  <div class="flex-grow-1">
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">沈阳</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">汕头</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">四平</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">三门峡</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">商洛</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">遂宁</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">宿迁</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">三亚</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">邵阳</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">商丘</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">上饶</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">深圳</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">随州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">石家庄</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">上海</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">十堰</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">绍兴</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">苏州</span>
                  </div>
                </div>
                <div class="neirong d-flex">
                  <div class="flex-shrink-0 f-14 f-gray-333 m-r-10">T:</div>
                  <div class="flex-grow-1">
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">天水</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">天津</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">泰安</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">台州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">太原</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">铁岭</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">唐山</span>
                  </div>
                </div>
              </div>
              <div v-show="onLetter===5" class='city-container' id="cityContainer">
                <div class="neirong d-flex">
                  <div class="flex-shrink-0 f-14 f-gray-333 m-r-10">U:</div>
                  <div class="flex-grow-1">
                  </div>
                </div>
                <div class="neirong d-flex">
                  <div class="flex-shrink-0 f-14 f-gray-333 m-r-10">V:</div>
                  <div class="flex-grow-1">
                  </div>
                </div>
                <div class="neirong d-flex">
                  <div class="flex-shrink-0 f-14 f-gray-333 m-r-10">W:</div>
                  <div class="flex-grow-1"><span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">武汉</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">潍坊</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">无锡</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">威海</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">渭南</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">芜湖</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">温州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">乌鲁木齐</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">乌兰察布</span>
                  </div>
                </div>
                <div class="neirong d-flex">
                  <div class="flex-shrink-0 f-14 f-gray-333 m-r-10">X:</div>
                  <div class="flex-grow-1"><span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">西宁</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">厦门</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">许昌</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">湘潭</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">邢台</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">兴安盟</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">咸阳</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">宣城</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">锡林郭勒盟</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">咸宁</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">西安</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">信阳</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">新乡</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">襄阳</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">西双版纳傣族自治州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">徐州</span>
                  </div>
                </div>
                <div class="neirong d-flex">
                  <div class="flex-shrink-0 f-14 f-gray-333 m-r-10">Y:</div>
                  <div class="flex-grow-1"><span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">运城</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">延边朝鲜族自治州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">玉溪</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">玉林</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">益阳</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">扬州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">岳阳</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">宜昌</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">榆林</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">烟台</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">盐城</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">宜宾</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">宜春</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">永州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">阳江</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">银川</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">伊犁哈萨克自治州</span>
                  </div>
                </div>
              </div>
              <div v-show="onLetter===5" class='city-container' id="cityContainer">
                <div class="neirong d-flex">
                  <div class="flex-shrink-0 f-14 f-gray-333 m-r-10">Z:</div>
                  <div class="flex-grow-1 city_name">
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">镇江</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">自贡</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">中山</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">遵义</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">珠海</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">昭通</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">张家口</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">郑州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">周口</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">漳州</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">湛江</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">淄博</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">株洲</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">资阳</span>
                    <span class="f-13 m-r-10 cursor-pointer city-item f-gray-999">肇庆</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="chooseInd" class="industry_content">
            <div class="domain-filter-container bg-white" @click="handleIndustry">
              <div class="d-flex">
                <div class="flex-shrink-0 domain f-gray-333 f-13 bg-gray-pc-2 p-h-5 cursor-pointer">科技类
                </div>
                <div class="flex-grow-1">
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 网络科技 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 电子科技 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 信息技术 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 数码科技 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 通讯科技 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 化工科技 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 能源科技 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 环保科技 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 环境科技 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 生物科技 </span>
                </div>
              </div>
              <div class="d-flex m-t-15">
                <div class="flex-shrink-0 domain f-gray-333 f-13 bg-gray-pc-2 p-h-5 cursor-pointer">贸易类
                </div>
                <div class="flex-grow-1">
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 贸易 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 电子商务 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 批发零售 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 国际贸易 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 商贸 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 工贸 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 经贸发展 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 建材销售 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 新材料销售 </span>
                </div>
              </div>
              <div class="d-flex m-t-15">
                <div class="flex-shrink-0 domain f-gray-333 f-13 bg-gray-pc-2 p-h-5 cursor-pointer">设计类
                </div>
                <div class="flex-grow-1">
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 平面设计 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 建筑设计 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 广告 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 文化传播 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 市场营销 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 企业形象策划 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 形象设计 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 动漫设计 </span>
                </div>
              </div>
              <div class="d-flex m-t-15">
                <div class="flex-shrink-0 domain f-gray-333 f-13 bg-gray-pc-2 p-h-5 cursor-pointer">服务类
                </div>
                <div class="flex-grow-1">
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 食品 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 餐饮 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 人力资源 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 商务咨询 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 企业管理咨询 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 会务 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 翻译 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 展览展示服务 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 礼仪服务 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 劳务派遣 </span>
                </div>
              </div>
              <div class="d-flex m-t-15">
                <div class="flex-shrink-0 domain f-gray-333 f-13 bg-gray-pc-2 p-h-5 cursor-pointer">产品类
                </div>
                <div class="flex-grow-1">
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 家具 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 箱包 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 服饰 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 袜业 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 电子电器 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 木业 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 汽配 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 化妆品 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 礼品 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 工艺品 </span>
                </div>
              </div>
              <div class="d-flex m-t-15">
                <div class="flex-shrink-0 domain f-gray-333 f-13 bg-gray-pc-2 p-h-5 cursor-pointer">其他</div>
                <div class="flex-grow-1">
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 房地产开发 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 房屋经纪 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 物流 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 物业管理 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 租赁 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 建筑工程 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 投资管理 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 实业 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 专利代理 </span>
                  <span class="f-13 m-r-20 cursor-pointer subdomain-item f-gray-999"> 企业管理 </span>
                </div>
              </div>
            </div>
          </div>
          <div v-show="chooseCom" class="company_content">
            <div class="ent-type-filter-container bg-white show" @click="handleCompany">
              <div class="ent-item f-bold f-14 f-blue-pc"> 有限公司</div>
              <div class="ent-item f-bold f-14 f-gray-333"> 合伙企业</div>
            </div>
          </div>
        </div>


      </div>
      <div class="main-content">
        <!-- 热销服务 -->
        <div class="hot-service">
          <div class="hot-service-content">
            <div class="hot-service-title"><strong>热销·精选服务</strong></div>
            <div class="hot-service-main-content">
              <a href="" v-if="hotPro.length>0">
                <router-link :to="{path:'/company_register',query:{id:item.id,category_id:item.category_id}}"
                  class="sum1_block" v-for="(item,index) in hotPro.slice(0,4)" :key="item.id">
                  <dl class="dl1">
                    <dt></dt>
                    <h4>{{item.title}}</h4>
                    <dd>{{item.brief}}</dd>
                    <dd><span>¥{{item.price}}</span><span style="font-size: 17px;">起</span></dd>
                  </dl>
                  <p>立即购买</p>
                </router-link>
              </a>
            </div>
          </div>
        </div>
        <!--工商注册-->
        <div class="business-register-out">
          <div class="business-register container min-width">
            <div class="img-container"></div>
            <div class="text-center f-gray-333">
              工商注册･记账一体化服务
            </div>
            <div class="text-center-content">
              <span class="f-26">服务优选</span>
              <span class="m-l-35 f-16">面向企服用户提供工商服务,选择你想要办理的公司类型得到一站式解决方案</span>
            </div>
            <div class="register-content">
              <div class="item bg-white " style="height: 210px; margin-right: 5px;"
                v-for="(item,index) in companyList.slice(0,4)" :key="index">
                <router-link :to="{path:'/company_register',query:{id:item.id,category_id:item.category_id}}">
                  <div class="p-item">

                    <div class="f-20 lh-24 f-gray-333">

                      <span class="f-bold title">{{item.title}}</span>

                    </div>

                    <div class="m-t-9 f-gray-999 f-14 text"
                      style="text-overflow: ellipsis;overflow: hidden;white-space: nowrap">
                      {{item.brief}}
                    </div>
                    <div class="d-flex m-t-15">
                      <div class="tab f-12 f-blue-pc tab-blue m-l-5" v-for="(tabItem,index) in item.tab" :key="index">
                        {{tabItem}}
                      </div>
                    </div>
                    <div class="f-30 f-orange-pc-2 price-container">
                      <span>¥</span> {{item.price}}
                      <span class="m-l-10 f-14 f-gray-999">起</span>

                    </div>
                  </div>
                </router-link>
              </div>
            </div>

            <div id="more_tag">
              <a href="">查看更多 &gt;</a>
            </div>

          </div>
        </div>
        <!-- 公司地址 -->
        <div class="company-address" style="margin-top: 40px; height: 615px;">
          <div class="container min-width service-container d-flex law-service-container"
            style="padding-top: 40px; position: relative;">

            <div v-if="pictureList.length>0" class="flex-shrink-0 p-relative left-img">
              <img :src="pictureList[0].image" alt="">
            </div>
            <table class="flex-grow-1 p-relative">
              <tr>
                <td v-if="companyList.length>0" class="item item-law-service" style="width: calc(25% + 80px);"
                  v-for="(item,index) in companyList.slice(0,6)" :key="index">
                  <router-link :to="{path:'/company_register',query:{id:item.id,category_id:item.category_id}}">
                    <div class="p-item p-relative p-item-hover">

                      <div class="f-20 lh-24 f-gray-333">
                        <span class="f-bold title">{{item.title}}</span>

                      </div>

                      <div class="m-t-10 f-gray-999 f-14 text">
                        {{item.brief}}
                      </div>
                      <div class="d-flex m-t-15">
                        <div class="tab f-12 f-blue-pc tab-blue m-l-5" v-for="(tabItem,index) in item.tab" :key="index">
                          {{tabItem}}
                        </div>
                      </div>
                      <div class="f-30 f-orange-pc-2 price-container">
                        <span>¥</span> {{item.price}}
                        <span class="m-l-10 f-14 f-gray-999">起</span>

                      </div>
                    </div>
                  </router-link>
                </td>
              </tr>
            </table>

            <div id="more_tag" style="position: absolute; bottom: -45px;right: 15px; font-size: 17px;font-weight: 700;">
              <a href="">查看更多 ></a>
            </div>
          </div>
        </div>
        <!-- 工商服务 -->
        <div class="work-business-service" style=" height: 380px;margin-bottom: 80px; background-color: #f5f5f5;">
          <div class="container  service-container d-flex law-service-container"
            style="padding-top: 40px; padding-bottom: 40px; position: relative;">

            <div v-if="pictureList.length>0" class="flex-shrink-0 p-relative left-img" style="height: 219px;">
              <img :src="pictureList[1].image" alt="">
            </div>
            <table class="flex-grow-1 p-relative">
              <tbody>
                <tr>
                  <td v-if="trademarkList.length>0" class="item item-law-service" style="width: calc(25% + 80px);"
                    v-for="(item,index) in trademarkList.slice(0,3)" :key="index">
                    <router-link :to="{path:'/company_register',query:{id:item.id,category_id:item.category_id}}">
                      <div class="p-item p-relative p-item-hover">

                        <div class="f-20 lh-24 f-gray-333">
                          <span class="f-bold title">{{item.title}}</span>

                        </div>

                        <div class="m-t-10 f-gray-999 f-14 text">
                          {{item.brief}}
                        </div>
                        <div class="d-flex m-t-15">
                          <div class="tab f-12 f-blue-pc tab-blue m-l-5" v-for="(tabItem,index) in item.tab"
                            :key="index">
                            {{tabItem}}
                          </div>
                        </div>
                        <div class="f-30 f-orange-pc-2 price-container">
                          <span>¥</span> {{item.price}}
                          <span class="m-l-10 f-14 f-gray-999">起</span>
                        </div>
                      </div>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
            <div id="more_tag" style="position: absolute; bottom: -5px;right: 15px; font-size: 17px;font-weight: 700;">
              <a href="">查看更多 &gt;</a>
            </div>
          </div>
        </div>
        <!-- 商标服务 -->
        <div class="business-service" style="margin-top: 40px; height: 615px;">
          <div class="container min-width service-container d-flex law-service-container"
            style="padding-top: 40px; position: relative;">

            <div v-if="pictureList.length>0" class="flex-shrink-0 p-relative left-img">
              <img :src="pictureList[2].image" alt="">
            </div>
            <table class="flex-grow-1 p-relative">
              <tr v-if="trademarkList.length>0">
                <td class="item item-law-service" style="width: calc(25% + 80px);"
                  v-for="(item,index) in trademarkList.slice(0,6)" :key="index">
                  <router-link :to="{path:'/company_register',query:{id:item.id,category_id:item.category_id}}"
                    style="text-decoration: none;">
                    <div class="p-item p-relative p-item-hover">
                      <div class="f-20 lh-24 f-gray-333">
                        <span class="f-bold title">{{item.title}}</span>
                      </div>
                      <div class="m-t-10 f-gray-999 f-14 text">
                        {{item.brief}}
                      </div>
                      <div class="d-flex m-t-15">
                        <div class="tab f-12 f-blue-pc tab-blue m-l-5" v-for="(tabItem,index) in item.tab" :key="index">
                          {{tabItem}}
                        </div>
                      </div>
                      <div class="f-30 f-orange-pc-2 price-container">
                        <span>¥</span> {{item.price}}
                        <span class="m-l-10 f-14 f-gray-999">起</span>

                      </div>
                    </div>
                  </router-link>
                </td>
              </tr>
            </table>

            <div id="more_tag" style="position: absolute; bottom: -45px;right: 15px; font-size: 17px;font-weight: 700;">
              <a href="">查看更多 ></a>
            </div>
          </div>
        </div>

        <!--资质许可-->
        <div class="business-register-out">
          <div class="business-register container min-width">
            <div class="img-container"></div>
            <div class="text-center f-gray-333">
              资质许可申办服务
            </div>
            <div class="text-center-content">
              <span class="f-26">服务优选</span>
              <span class="m-l-35 f-16">面向企服用户提供资质许可,经营许可认证服务，合规经营，行稳致远</span>
            </div>
            <div v-if="authentication.length>0" class="register-content">
              <div class="item bg-white " style="height: 210px; margin-right: 5px;"
                v-for="(item,index) in authentication.slice(0,4)" :key="item.parent_id">
                <router-link :to="{path:'/company_register',query:{id:item.id,category_id:item.category_id}}">
                  <div class="p-item">

                    <div class="f-20 lh-24 f-gray-333">

                      <span class="f-bold title">{{item.title}}</span>

                    </div>

                    <div class="m-t-9 f-gray-999 f-14 text"
                      style="text-overflow: ellipsis;overflow: hidden;white-space: nowrap">
                      {{item.brief}}
                    </div>
                    <div class="d-flex m-t-15">
                      <div class="tab f-12 f-blue-pc tab-blue m-l-5" v-for="(tabItem,index) in item.tab" :key="index">
                        {{tabItem}}
                      </div>
                    </div>
                    <div class="f-30 f-orange-pc-2 price-container">
                      <span>¥</span> {{item.price}}/<span class="f-14" style="color: #ff8b02;">件 &nbsp</span>
                      <span class="m-l-10 f-14 f-gray-999">起</span>

                    </div>
                  </div>
                </router-link>
              </div>
            </div>
            <div id="more_tag">
              <a href="">查看更多 &gt;</a>
            </div>
          </div>
        </div>

        <!-- 认证服务 -->
        <div class="business-service" style="margin-top: 40px; height: 615px;">
          <div class="container min-width service-container d-flex law-service-container"
            style="padding-top: 40px; position: relative;">

            <div v-if="pictureList.length>0" class="flex-shrink-0 p-relative left-img">
              <img :src="pictureList[3].image" alt="">
            </div>
            <table class="flex-grow-1 p-relative">
              <tr>
                <td v-if="authentication2.length>0" class="item item-law-service" style="width: calc(25% + 80px);"
                  v-for="(item,index) in authentication2.slice(0,6)" :key="index">
                  <router-link :to="{path:'/company_register',query:{id:item.id,category_id:item.category_id}}"
                    style="text-decoration: none;">
                    <div class="p-item p-relative p-item-hover">
                      <div class="f-20 lh-24 f-gray-333">
                        <span class="f-bold title">{{item.title}}</span>
                      </div>
                      <div class="m-t-10 f-gray-999 f-14 text">
                        {{item.brief}}
                      </div>
                      <div class="d-flex m-t-15">
                        <div class="tab f-12 f-blue-pc tab-blue m-l-5" v-for="(tabItem,index) in item.tab" :key="index">
                          {{tabItem}}
                        </div>
                      </div>
                      <div class="f-30 f-orange-pc-2 price-container">
                        <span>¥</span> {{item.price}}
                        <span class="m-l-10 f-14 f-gray-999">起</span>

                      </div>
                    </div>
                  </router-link>
                </td>
              </tr>
            </table>

            <div id="more_tag" style="position: absolute; bottom: -45px;right: 15px; font-size: 17px;font-weight: 700;">
              <a href="">查看更多 ></a>
            </div>
          </div>
        </div>

        <!-- 法律服务 -->
        <div v-if="pictureList.length>0" class="business-service"
          style="margin-top: 40px; height: 615px; background-color: #f5f5f5;">
          <div class="container min-width service-container d-flex law-service-container"
            style="padding-top: 40px; position: relative;">

            <div class="flex-shrink-0 p-relative left-img">
              <img style="width: 100%;" :src="pictureList[4].image" alt="">
            </div>
            <table v-if="lawList.length>0" class="flex-grow-1 p-relative">
              <tr>
                <td class="item item-law-service" style="width: calc(25% + 80px);"
                  v-for="(item,index) in lawList.slice(0,6)" :key="index">
                  <router-link :to="{path:'/company_register',query:{id:item.id,category_id:item.category_id}}"
                    style="text-decoration: none;">
                    <div class="p-item p-relative p-item-hover">
                      <div class="f-20 lh-24 f-gray-333">
                        <span class="f-bold title">{{item.title}}</span>
                      </div>
                      <div class="m-t-10 f-gray-999 f-14 text">
                        {{item.brief}}
                      </div>
                      <div class="d-flex m-t-15">
                        <div class="tab f-12 f-blue-pc tab-blue m-l-5" v-for="(tabItem,index) in item.tab" :key="index">
                          {{tabItem}}
                        </div>
                      </div>
                      <div class="f-30 f-orange-pc-2 price-container">
                        <span>¥</span> {{item.price}}
                        <span class="m-l-10 f-14 f-gray-999">起</span>

                      </div>
                    </div>
                  </router-link>
                </td>
              </tr>
            </table>

            <div id="more_tag" style="position: absolute; bottom: -45px;right: 15px; font-size: 17px;font-weight: 700;">
              <a href="">查看更多 ></a>
            </div>
          </div>
        </div>

        <!-- 精选咨询 -->
        <div class="company_trends">
          <div class="company_trends_content">
            <div class="hot-service-title"><strong>热销·精选服务</strong></div>
            <div class="company_trends_main">
              <ul v-for="(item,index) in newsList.slice(0,4)" :key="index">
                <li>
                  <a href="">
                    <div class="left">
                      <div>
                        <p class="date">{{item.create_time[2]}} <span></span></p>
                        {{item.create_time[0]}}-{{item.create_time[1]}}
                      </div>
                    </div>
                    <div class="right">
                      <p class="title">{{item.title}}</p>
                      <p class="txt">{{item.brief}}</p>
                      <p class="type">{{item.tab}}</p>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- 服务承诺 -->
        <div class="index-services">
          <div class="w_1180">
            <h2 class="index-services-title"><strong>我们的服务承诺</strong></h2>
            <div class="ul-content">
              <ul class="ul1">
                <li class="ico1">
                  <span>服务保障</span>
                  <p>专属客服对接<br>一对一提供服务</p>
                </li>
                <li class="ico2">
                  <span>时间保障</span>
                  <p>及时提交<br>快速办理</p>
                </li>
                <li class="ico3">
                  <span>诚信保障</span>
                  <p>服务全程跟踪<br>严格保护客户资料</p>
                </li>
                <li class="ico4">
                  <span>先行赔付</span>
                  <p>出错先赔付<br>代账更放心</p>
                </li>
                <li class="ico5">
                  <span>先行赔付</span>
                  <p>出错先赔付<br>代账更放心</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div>
      </div>
      <!-- 底部 -->
      <div>
        <Footer :footerList="footerList" :topList="topList" />
      </div>
      <!-- 弹窗 -->
      <GlobalDialog :searchInput="searchInput" :comName="comName" :profess="profess" ref="globalDialog" />
    </div>
  </div>
</template>

<script>
  import Footer from '../components/footer/index.vue'
  export default {
    components: {
      Footer
    },
    props:['footerList','topList'],
    data() {
      return {
        isOn: 0,
        chooseCom: false,
        chooseInd: false,
        chooseCity: false,
        companyText: '',
        IndustryText: '',
        comName: '',
        profess: '',
        cityText: '',
        onLetter: 1,
        cityMap: [
          { A: ['鞍山', '安阳', '安康'] },
          { B: ['毫州', '巴中', '保山', '北京', '白山', '宝鸡', '北海', '百色', '包头', '巴音郭楞蒙古自治州', '保定'] },
          { C: ['承德', '郴州', '长春', '楚雄彝族自治州', '滁州', '沧州', '常德', '赤峰', '重庆', '朝阳', '成都', '昌吉回族自治州', '常州', '长治', '长沙'] },
          { D: ['德阳', '大同', '大理白族自治州', '达州', '东莞', '德州', '大连'] },
          { E: ['鄂尔多斯', '恩施土家族苗族自治州'] },
          { F: ['福州', '抚州', '佛山', '阜阳'] },
          { G: ['赣州', '广州', '桂林', '贵阳', '贵港'] },
          { H: ['淮南', '黄石', '杭州', '合肥', '惠州', '黄冈', '红河哈尼族彝族自治州', '河源', '呼伦贝尔', '菏泽', '呼和浩特', '衡阳', '汉中', '海口', '哈密', '湖州', '邯郸', '怀化', '淮安', '哈尔滨', '衡水'] },
          { I: [] },
          { J: ['景德镇', '晋中', '济南', '荆州', '吉安', '九江', '济宁', '酒泉', '焦作', '揭阳', '锦州', '晋城', '荆门', '江门', '金华', '嘉兴'] },
          { K: ['开封', '昆明'] },
          { L: ['拉萨', '龙岩', '廊坊', '柳州', '吕梁', '漯河', '聊城', '洛阳', '临沂', '凉山彝族自治州', '来宾', '泸州', '连云港', '六安', '六盘水'] },
          { M: ['茂名', '眉山'] },
          { N: ['南昌', '宁波', '南通', '南宁', '南阳', '南京', '南充'] },
          { O: [] },
          { P: ['普洱', '濮阳', '平顶山', '莆田', '萍乡'] },
          { Q: ['清远', '泉州', '曲靖', '钦州', '青岛', '衢州'] },
          { R: ['日照'] },
          { S: ['沈阳', '汕头', '四平', '三门峡', '商洛', '遂宁', '宿迁', '三亚', '邵阳', '商丘', '上饶', '深圳', '随州', '石家庄', '上海', '十堰', '绍兴', '苏州'] },
          { T: ['天水', '天津', '泰安', '台州', '太原', '铁岭', '唐山'] },
          { U: [] },
          { V: [] },
          { W: ['武汉', '潍坊', '无锡', '威海', '渭南', '芜湖', '温州', '乌鲁木齐', '乌兰察布'] },
          { X: ['西宁', '厦门', '许昌', '湘潭', '邢台', '兴安盟', '咸阳', '宣城', '锡林郭勒盟', '咸宁', '西安', '信阳', '新乡', '襄阳', '西双版纳傣族自治州', '徐州'] },
          { Y: ['运城', '延边朝鲜族自治州', '玉溪', '玉林', '益阳', '扬州', '岳阳', '宜昌', '榆林', '烟台', '盐城', '宜宾', '宜春', '永州', '阳江', '银川', '伊犁哈萨克自治州'] },
          { Z: ['镇江', '自贡', '中山', '遵义', '珠海', '昭通', '张家口', '郑州', '周口', '漳州', '湛江', '淄博', '株洲', '资阳', '肇庆'] }
        ],
        searchInput: '',
        ServiceList: [],
        productList: [],
        projectList: [],
        pictureList: [],
        companyList: [],
        hotPro: [],
        newsList: [],
        trademarkList: [],
        authentication: [],
        authentication2: [],
        lawList: [],
      }
    },
    computed: {
      newCity() {
        return this.cityText ? this.cityText : '选择城市'
      },
      newIndustry() {
        return this.IndustryText ? this.IndustryText : '选择行业'
      },
      newCompany() {
        return this.companyText ? this.companyText : '选择公司类型'
      },
    },
    watch: {
      newCity(newVal, oldVal) {
        this.showCity()
      },
      newIndustry(newVal, oldVal) {
        this.showInd()
      },
      newCompany(newVal, oldVal) {
        this.showCom()
      }
    },
    mounted() {
      // this.getServiceList();
      this.getProduct();
      this.getNews();
      this.getPicture();
      this.groupCitiesByFive(this.cityMap)
    },
    methods: {
      //获取热销精品列表
      // async getServiceList() {
      //   const res = await this.$request.get('HotService/getPaginate');
      //   console.log(res,'热门');
      //   if (res.data.code === 0) {
      //     this.ServiceList = res.data.data.data
      //     this.ServiceList.reverse()
      //   }
      // },
      //获取产品
      async getProduct() {
        const res = await this.$request.post('Product/getAll');
        if (res.data.code === 0) {
          this.productList = res.data.data;
          this.productList.forEach((item) => {
            item.tab = item.tab.split("&")
          })
          //整理数据
          this.hotPro = this.productList.filter(item => item.is_hot === 1)
          this.companyList = this.productList.filter(item => item.category_id === 2)
          this.trademarkList = this.productList.filter(item => item.category_id === 1)
          this.authentication = this.productList.filter(item => item.category_id === 3)
          this.authentication2 = this.productList.filter(item => item.category_id === 4)
          this.lawList = this.productList.filter(item => item.category_id === 5)
        }

      },
      async getProject() {
        const res = await this.$request.post('Productproject/getAll');
        if (res.data.code === 0) {
        }
      },

      async getPicture() {
        const res = await this.$request.post('Homepicture/getPaginate');
        if (res.data.code === 0) {
          this.pictureList = res.data.data.data
        }
      },

      async getNews() {
        const res = await this.$request.post('News/getAll')
        if (res.data.code === 0) {
          this.newsList = res.data.data
          this.newsList = this.newsList.filter(item => item.is_hot === 1)
          this.newsList.forEach(item => {
            item.create_time = item.create_time.split(' ')[0]
            item.create_time = item.create_time.split('-')
            item.year = item.create_time[0]
            item.month = item.create_time[1]
            item.day = item.create_time[2]
          })
        }
      },
      isChange(item) {
        this.isOn = item
        this.searchInput = ''
      },
      showCity() {
        this.chooseCity = !this.chooseCity
      },
      showInd() {
        this.chooseInd = !this.chooseInd
      },
      showCom() {
        this.chooseCom = !this.chooseCom
      },
      groupCitiesByFive(cityMap) {
        const groupedCities = {};

        this.cityMap.forEach((group, index) => {
          const letter = Object.keys(group)[0]; // 获取当前分组的字母标识
          const cities = group[letter]; // 获取当前分组的城市数组

          // 如果分组标识不在结果对象中，则初始化它
          if (!groupedCities[letter]) {
            groupedCities[letter] = [];
          }

          // 将城市添加到当前分组的数组中，如果数组长度达到5，则创建新的分组
          if (groupedCities[letter].length < 5) {
            groupedCities[letter].push(...cities);
          } else {
            // 如果当前分组已满，创建新的分组标识，并将剩余城市添加到新分组
            let newLetter = String.fromCharCode(letter.charCodeAt(0) + 1);
            while (groupedCities[newLetter] && groupedCities[newLetter].length >= 5) {
              newLetter = String.fromCharCode(newLetter.charCodeAt(0) + 1);
            }
            groupedCities[newLetter] = cities;
          }
        });

        this.cityMap = groupedCities
      },
      isAct(item) {
        this.onLetter = item
      },
      handleCity(event) {
        // 检查点击的目标元素是否是城市名称的span
        if (event.target.classList.contains('city-item')) {
          const cityName = event.target.textContent;
          this.cityText = cityName
        }
      },
      handleIndustry(event) {
        // 检查点击的目标元素是否是行业名称的span
        if (event.target.classList.contains('cursor-pointer')) {
          const cityName = event.target.textContent;
          this.IndustryText = cityName
        }
      },
      handleCompany(event) {
        // 检查点击的目标元素是否是行业名称的span
        if (event.target.classList.contains('ent-item')) {
          const cityName = event.target.textContent;
          this.companyText = cityName
        }
      },
      openDialog(item, item2) {
        this.comName = item
        this.profess = item2
        this.$refs.globalDialog.dialogVisible = true;
      },
    }
  }
</script>
<style lang="scss" scoped>
  .home {
    .container {
      margin: 0 auto;
      padding: 0;

      .top-content {
        width: 100%;
        height: 401px;
        background-image: url('../assets/img/top-bg-gea.new.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        img {
          display: block;
          width: 351px;
          height: 50px;
          margin: 50px auto 0;
        }

        .search_kuang {
          position: relative;
          width: 850px;
          margin: 215px auto 0;
          display: flex;

          .top_title {
            position: absolute;
            left: 49%;
            transform: translateX(-50%);
            top: -145px;
            font-size: 35px;
            color: #ffe80d;
            text-shadow: 1px 2px 4px rgba(0, 0, 0, .7);
            letter-spacing: 3px;
          }

          .search {
            width: 850px;
            height: 50px;
            display: flex;
            margin: 0 auto;

            .search_content {
              height: 100%;
              border-radius: 0;
              width: 100%;
              padding-left: 10px;
              background-color: white;
            }

            .el-button {
              width: 140px;
              height: 50px;
              background: #ff8b02;
              font-size: 16px;
              color: white;
              border-color: #ff8b02;
            }
          }

          .jiantou {
            width: 10px;
            height: 10px;
            left: 42%;
            transform: translateX(2px);
            top: -20px;
            border: 5px solid;
            border-color: #FFF transparent transparent;
            position: absolute;
          }

          .isCity {
            width: 100px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            color: #333;
            background-color: #fece9b;
            cursor: pointer;

            span {
              font-size: 14px;
              padding-right: 3px;
            }
          }

          .isIndustry {
            width: 100px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            color: #333;
            background-color: #fece9b;
            cursor: pointer;
            position: absolute;
            right: 310px;
            top: 5px;
            border-radius: 4px;

            span {
              font-size: 14px;
              padding-right: 3px;
            }
          }

          .isCompany {
            width: 160px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            color: #333;
            background-color: #fece9b;
            cursor: pointer;
            position: absolute;
            right: 140px;
            top: 5px;
            border-radius: 4px;

            span {
              font-size: 14px;
              padding-right: 3px;
            }
          }

          .button {
            position: absolute;
            left: 50%;
            transform: translateX(-120%);
            top: -60px;
            width: 86px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            border-radius: 4px;
            color: #fff;
            background: #ff8b02;
            cursor: pointer;
            font-weight: 700;
            font-size: 15px;

            &:nth-child(2) {
              left: 65%;
              transform: translateX(-145%);
            }
          }

          .isChoose {
            background-color: #FFFFFF;
            color: #ff8b02;
          }

          .city_content {
            position: absolute;
            width: 100%;
            height: auto;
            top: 55px;
            padding: 15px 17px 20px;
            background-color: #FFFFFF;
            box-shadow: 0 4px 4px 0 rgb(0 0 0 / 5%);

            >input {
              padding-left: 31px;
              display: block;
              width: 100%;
              height: 36px;
              line-height: 36px;
              border-radius: 2px;
              border: 1px solid #ccc;
              background: #f5f5f5;
            }

            .city_code {
              padding-top: 10px;
              border-bottom: 1px solid #dedede;

              ul {
                display: flex;

                li {
                  cursor: pointer;
                  display: inline-block;
                  height: 25px;
                  line-height: 25px;
                  background: #fff;
                  padding: 0 10px;
                  border: 1px solid transparent;
                  border-top: 2px solid transparent;
                  color: #333;
                  font-weight: 700;
                }

                .isActive {
                  border: 1px solid #dedede;
                  border-top: 2px solid #ff8b02;
                  border-bottom: 1px solid #FFFFFF;
                }
              }
            }

            .city_menu {
              .city-container {
                .neirong {
                  margin-top: 10px;

                  .flex-shrink-0 {
                    .flex-grow-1 {
                      span {
                        line-height: 25px;

                        &:hover {
                          color: #ff8b02;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .industry_content {
            position: absolute;
            width: 100%;
            height: auto;
            top: 55px;
            padding: 15px 17px 20px;
            z-index: 99;
            background-color: #FFFFFF;
            box-shadow: 0 4px 4px 0 rgb(0 0 0 / 5%);

            .domain-filter-container {
              .d-flex {
                .domain {
                  margin-right: 10px;
                }

                span {
                  &:hover {
                    color: #ff8b02;
                  }
                }
              }
            }
          }

          .company_content {
            position: absolute;
            width: 150px;
            height: auto;
            top: 55px;
            right: 144px;
            text-align: center;
            padding: 15px 17px 20px;
            z-index: 99;
            background-color: #FFFFFF;
            box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
            border-radius: 4px;

            .ent-type-filter-container {
              .ent-item {
                color: #333333;
                padding: 10px;
                cursor: pointer;

                &:hover {
                  background-color: #f5f5f5;
                  color: #ff8b02;
                }
              }
            }
          }
        }

      }

      .main-content {
        .title {
          font-size: 20px !important;
        }

        .hot-service {
          height: 500px;
          box-sizing: border-box;
          padding: 40px 0;

          .hot-service-content {
            width: 1180px;
            height: 100%;
            margin: 0 auto;
            padding: 0 15px;
            overflow: hidden;

            .hot-service-title {
              color: #333;
              font-size: 30px;
              text-align: center;
              font-weight: 700;
              background: url('../assets/img/line.png') no-repeat center;
            }

            .hot-service-main-content {
              a {
                cursor: pointer;
                overflow: hidden;

                .sum1_block {
                  background-color: white !important;
                  border: 5px solid;
                  width: 270px;
                  border: 0;
                  float: left;
                  height: 100%;
                  margin-top: 40px;
                  margin-right: 17px;
                  background: url('../assets/img/sum1_block_new.png') no-repeat top;

                  .dl1 {
                    dt {
                      width: 88px;
                      height: 88px;
                      border-radius: 100%;
                      text-align: center;
                      margin: 15px auto 0 auto;
                      background: url('../assets/img/ico5.png') no-repeat center center;
                      background-color: white;
                    }

                    h4 {
                      background: none;
                      border: solid 1px transparent;
                      height: 50px;
                      line-height: 50px;
                      color: #ff8b02;
                      text-align: center;
                      font-size: 20px;
                      font-weight: normal;
                    }

                    dd {
                      color: #a7a2a2;
                      text-align: center;
                      font-size: 14px;
                      overflow: hidden;
                      margin: 10px 0;

                      span {
                        color: #ff8b02;
                        font-size: 28px;
                      }
                    }
                  }

                  >p {
                    width: 234px;
                    height: 38px;
                    line-height: 38px;
                    font-size: 14px;
                    text-align: center;
                    border: 1px solid #ff8b02;
                    margin: 22px auto 10px auto;
                  }

                  &:hover {
                    background: none !important;
                    z-index: 999;
                    -webkit-box-shadow: 0 0 4px 2px #fece9b;
                    box-shadow: 0 0 4px 2px #fece9b;
                  }

                  &:hover p {
                    background-color: #ff8b02;
                    color: white;
                  }

                  &:hover .dl1 dt {
                    background-color: #ff8b02 !important;
                    background: url('../assets/img/ico5_a.png') no-repeat center;
                  }
                }
              }
            }

          }
        }

        .business-register-out {
          background-color: #f5f5f5;
          height: 550px;
          padding: 80px 0 0;
          box-sizing: border-box;

          .business-register {
            position: relative;

            .img-container {
              position: absolute;
              top: -31px;
              right: -72px;
              background: url(../assets/img/bg_normal.d39e454b.png) no-repeat;
              background-size: 364px 287px;
              background-position: right 0 top 0;
              width: 364px;
              height: 287px;
            }

            .text-center {
              text-align: center;
              font-weight: 700;
              font-size: 30px;
            }

            .text-center-content {
              margin-bottom: 30px;
              margin-top: 40px;
              font-weight: 700;
              color: #333;

              .f-26 {
                font-size: 26px;
              }

              .m-l-35 {
                margin-left: 35px;
              }
            }

            .register-content {
              width: 100%;

              .item {
                height: 210px;
                margin-right: 8px;
                float: left;
                width: calc(25% - 9px);
                border: 1px solid #e9e9e9;
                position: relative;
                cursor: pointer;
                background-color: white;

                a {
                  color: #ff8b02;
                  touch-action: manipulation;
                  text-decoration: none;
                  background-color: transparent;

                  .p-item {
                    padding: 29px 24px 30px;
                    height: 100%;
                    transition: all .4s;

                    .lh-24 {
                      line-height: 24px;

                      .f-bold {
                        font-weight: 700;
                      }
                    }

                    .f-20 {
                      font-size: 20px;
                    }

                    .f-gray-333 {
                      color: #333;
                    }

                    .m-t-9 {
                      margin-top: 9px;
                    }

                    .f-14 {
                      font-size: 14px;
                    }

                    .f-gray-999 {
                      color: #999;
                    }

                    .text {
                      text-overflow: ellipsis;
                      overflow: hidden;
                      white-space: nowrap;
                    }

                    .m-t-15 {
                      margin-top: 15px;
                    }

                    .d-flex {
                      display: flex;

                      .tab {
                        padding: 2px 6px;
                        white-space: nowrap;
                      }

                      .tab-blue {
                        background: #fece9b;
                      }

                      .f-12 {
                        font-size: 12px;
                      }

                      .m-l-5 {
                        margin-left: 5px;
                      }
                    }

                    .price-container {
                      position: absolute;
                      left: 24px;
                      bottom: 30px;
                      height: 36px;
                      line-height: 36px;
                    }

                    .f-30 {
                      font-size: 30px;
                    }

                    .f-orange-pc-2 {
                      color: #ff8b02;
                    }
                  }
                }
              }

              .item:hover {
                border: 1px solid #e9e9e9;
                z-index: 999;
                -webkit-box-shadow: 0 0 4px 2px rgb(0 0 0 / 15%);
                box-shadow: 0 0 4px 2px rgb(0 0 0 / 15%);
                transition: transform 0.4s;
                transform: scale(1.01);
              }
            }

          }
        }

        .container {
          padding-right: 15px;
          padding-left: 15px;
          margin-right: auto;
          margin-left: auto;
        }

        .min-width {
          width: 1285px;
          max-width: 1285px;
        }

        .service-container2 {

          margin: 0 auto;
        }

        .container {
          padding-top: 40px;
          position: relative;
          width: 1285px;
          max-width: 1285px;
          padding-right: 15px;
          padding-left: 15px;
          margin: 0 auto;

          .flex-shrink-0 {
            flex-shrink: 0
          }

          .p-relative {
            position: relative;
          }

          .left-img {
            background-repeat: no-repeat;
            background-size: cover;
            width: 295px;
            height: 440px;
          }

          .flex-grow-1 {
            -webkit-box-flex: 1;
            flex-grow: 1;
          }

          table {
            border-collapse: collapse;
            width: 885px;
          }

          .item {
            float: left;
            width: calc(25% + 80px);
            height: 220px;
            border: 1px solid #e9e9e9;
            position: relative;
            cursor: pointer;

            .p-item {
              padding: 29px 24px 30px;
              height: 100%;
              transition: all .4s;
            }
          }
        }

        .d-flex {
          display: flex;
        }


        .item-law-service {
          background-color: white;

          a {
            text-decoration: none;
          }
        }

        .item-law-service:hover {
          background-repeat: no-repeat;
          background-size: 266px 252px;
          background-position: bottom -39px right -59px;
          height: 220px;
          transform: scale(1.01);
          transition: transform .4s;
          background-image: url(../assets/img/bg_law.f2b40978.png);
        }

        .hot-service {
          box-sizing: border-box;
          padding: 40px 0;
        }

        .lh-24 {
          line-height: 24px;
        }

        .f-20 {
          font-size: 20px;
        }

        .f-gray-333 {
          color: #333;
        }

        .m-t-10 {
          margin-top: 10px;
        }

        .f-14 {
          font-size: 14px;
        }

        .f-gray-999 {
          color: #999;
        }

        .text {
          line-height: 18px;
        }

        .m-t-15 {
          margin-top: 15px;
        }

        .d-flex {
          display: flex;
        }

        .price-container {
          position: absolute;
          left: 24px;
          bottom: 30px;
          height: 36px;
          line-height: 36px;
        }

        .f-30 {
          font-size: 30px;
        }

        .f-orange-pc-2 {
          color: #ff8b02;
        }

        .f-bold {
          font-weight: 700;
        }

        .tab {
          color: #ff8b02;
          padding: 2px 6px;
          white-space: nowrap;
        }

        .tab-blue {
          background: #fece9b;
        }

        .f-12 {
          font-size: 12px;
        }

        .m-l-5 {
          margin-left: 5px;
        }

        #more_tag {
          position: absolute;
          bottom: -300px;
          right: 27px;
          font-size: 17px;
          font-weight: 700;

          a {
            text-decoration: none;
            color: #ff8b02;
          }
        }

        .hot-service-title {
          color: #333;
          font-size: 30px;
          text-align: center;
          font-weight: 700;
          background: url('../assets/img/line.png') no-repeat center;
        }

        /* 时间问题下面用的都是css-------------------------- */
        .company_trends {
          height: 626px;
          box-sizing: border-box;
          padding-top: 65px;
        }

        .company_trends .company_trends_content .company_trends_main {
          width: 1180px;
          margin: 75px auto 0 auto;
        }

        .company_trends .company_trends_content .company_trends_main ul {
          display: inline-block;
          width: 49%;
          box-sizing: border-box;
          vertical-align: middle;
        }

        .company_trends .company_trends_content .company_trends_main ul:nth-child(1) {
          padding-right: 53px;
          border-right: 1px solid #f5f5f5;
        }

        .company_trends .company_trends_content .company_trends_main ul:nth-child(3) {
          padding-right: 53px;
          border-right: 1px solid #f5f5f5;
        }

        .company_trends .company_trends_content .company_trends_main ul:nth-child(2) {
          padding-left: 40px;
        }

        .company_trends .company_trends_content .company_trends_main ul:nth-child(4) {
          padding-left: 40px;
        }



        .company_trends_main ul li {
          margin: 25px 0;
          list-style: none;
        }

        .company_trends_main ul li a {
          display: flex;
          text-decoration: none;
          color: #575757;
          font-size: 12px
        }

        .company_trends_main ul li a:hover .left::before {
          display: none;
        }

        .company_trends_main ul li a:hover .left::after {
          display: none;
        }

        .company_trends_main ul li a:hover .left div {
          background-color: #ff8b02;
          color: white;
        }

        .company_trends_main ul li a:hover .left .date {
          color: white;
        }

        .company_trends_main ul li a:hover .date span {
          background-color: white;
        }

        .company_trends_main ul li a:hover .right .type {
          border: 1px solid #ff8b02;
          background-color: white;
          color: #ff8b02;
        }

        .company_trends_main ul li a:hover .right .title {
          color: #ff8b02;
        }


        .company_trends_main ul li a .left::before {
          content: "";
          position: absolute;
          width: 50%;
          height: 50%;
          z-index: 4;
          transition: all .4s;
          background-color: var(--themaOrange);
        }

        .company_trends_main ul li a .left {
          position: relative;
          width: 90px;
          height: 128px;
          margin-right: 20px;
          border: 1px solid #c3c6cd;
          font-size: 16px;
          transition: all .4s;
          color: #ff8b02;
        }

        .company_trends_main ul li a .left::before {
          content: "";
          position: absolute;
          top: -1px;
          left: 45px;
          right: -1px;
          bottom: -1px;
        }

        .company_trends_main ul li a .left::after {
          content: "";
          position: absolute;
          top: 70px;
          left: -1px;
          right: 55px;
          bottom: -1px;
        }


        .company_trends_main ul li a .left::before {
          background-color: #ff8b02;
        }

        .company_trends_main ul li a .left::after {
          background-color: #ff8b02;
        }




        .company_trends_main ul li a .right {
          flex: 1;
        }

        .company_trends_main ul li a .right .title {
          border-bottom: 1px solid #eee;
          font-size: 18px;
          line-height: 18px;
          padding-bottom: 18px;
          transition: all .4s;
          color: #575757;
          margin-bottom: 18px;
        }

        .company_trends_main ul li a .right .txt {
          margin: 16px auto 18px;
          font-size: 14px;
          color: #888888;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .company_trends_main ul li a .right .type {
          float: left;
          margin-left: 5px;
          background-color: #f6f6f6;
          width: 80px;
          line-height: 28px;
          border: 1px solid transparent;
          transition: all .4s;
          font-size: 14px;
          color: #aaaaaa;
          text-align: center;
        }


        .company_trends_main ul li a .left>div {
          position: relative;
          display: flex;
          height: 100%;
          text-align: center;
          flex-direction: column;
          justify-content: center;
          z-index: 5;
          transition: all .34s;
          background-color: white;
        }

        .company_trends_main ul li .date {
          font-size: 30px;
          font-weight: 700;
          color: #ff8b02;
        }

        .company_trends_main ul li .date span {
          display: block;
          width: 20px;
          height: 1px;
          margin: 5px auto;
          background-color: #ff8b02;
        }

        .index-services {
          width: 100%;
          background: url('../assets/img/index-footer.jpg') no-repeat top center;
          background-size: cover;
          height: auto;
          overflow: hidden;
          margin: 15px auto 0 auto;
          padding: 50px 0;
        }

        .index-services-title {
          color: #575757;
          font-size: 20px;
          text-align: center;
          margin-bottom: 15px;
          font-weight: 700;
          background: url('../assets/img/line.png') no-repeat center;
        }

        .index-services .ul1 {
          float: left;
          width: 100%;
          margin: 0 auto;
        }

        .index-services .ul1 li {
          text-align: center;
          float: left;
          padding-top: 83px;
          width: 230px;
        }

        .index-services .ul1 .ico1:hover {
          background: url('../assets/img/ul1-aa.png') no-repeat top center;
        }

        .index-services .ul1 .ico2:hover {
          background: url('../assets/img/ul1-bb.png') no-repeat top center;
        }

        .index-services .ul1 .ico3:hover {
          background: url('../assets/img/ul1-cc.png') no-repeat top center;
        }

        .index-services .ul1 .ico4:hover {
          background: url('../assets/img/ul1-dd.png') no-repeat top center;
        }

        .index-services .ul1 .ico5:hover {
          background: url('../assets/img/img01_a.png') no-repeat top center;
        }

        .index-services .ul1 li span {
          display: block;
          color: #595757;
          font-size: 16px;
          margin: 15px 0;
        }

        .index-services .ul1 li p {
          display: block;
          color: #8b8b8b;
          font-size: 14px;
        }

        .index-services .ul1 .ico1 {
          background: url('../assets/img/ul1-a.png') no-repeat top center;
        }

        .index-services .ul1 .ico2 {
          background: url('../assets/img/ul1-b.png') no-repeat top center;
        }

        .index-services .ul1 .ico3 {
          background: url('../assets/img/ul1-c.png') no-repeat top center;
        }

        .index-services .ul1 .ico4 {
          background: url('../assets/img/ul1-d.png') no-repeat top center;
        }

        .index-services .ul1 .ico5 {
          background: url('../assets/img/img01.png') no-repeat top center;
        }

        .index-services .ul-content {
          margin: 0 auto;
          width: 1180px;
        }

        .index-services .ul-content ul li {
          list-style: none;
        }
      }

    }
  }
</style>