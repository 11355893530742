<template>
  <el-dialog :lockScroll="false" title="请提供您的联系方式，方便我们为您提供更多服务" :visible.sync="dialogVisible" width="480px">
    <el-form :model="consultationForm" label-width="100px" :rules="rules" ref="consultationForm">
      <el-form-item :label="`${comName}名称`">
        <el-input v-model="consultationForm.category" placeholder="请输入商标名称"></el-input>
      </el-form-item>
      <el-form-item style="margin-bottom: 0;" label="联系电话" prop="phone">
        <el-input v-model="consultationForm.phone" placeholder="请输入联系电话"></el-input>
        <div class="appdia">
          <img class="safety" src="/safety-certificate.svg" alt="">
          <span class="safety2">信息保护中，请放心填写</span>
        </div>
      </el-form-item>
      <el-form-item label="联系人">
        <el-input v-model="consultationForm.contact" placeholder="请输入联系人姓名"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitConsultation">立即咨询{{profess}}专家</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    props: {
      searchInput: {
        type: String,
        default: '' // 设置默认值为空字符串
      },
      comName: {
        type: String,
        default: '商标' // 设置默认值为空字符串
      },
      profess: {
        type: String,
        default: '商标' // 设置默认值为空字符串
      }
    },
    data() {
      return {
        dialogVisible: false,
        consultationForm: {
          category: '',
          phone: '',
          contact: ''
        },
        rules: {
          phone: [
            { required: true, message: '请输入联系电话', trigger: 'blur' }
          ]
        }
      };
    },
    created() {
      this.consultationForm.category = this.searchInput
    },
    watch: {
      searchInput(newVal, oldVal) {
        this.consultationForm.category = this.searchInput
      }
    },

    methods: {
      async submitConsultation() {
        this.$refs.consultationForm.validate(async valid => {
          if (valid) {
            let res = await this.$request.post('consult/add', this.consultationForm)
            if (res.data.code === 0) {
              this.$message.success("提交成功")
            }
            // 发送请求...
            this.dialogVisible = false;
          } else {
            alert('提交失败，联系电话不能为空');
            return false;
          }
        });
      }
    }
  };
</script>

<style lang="css" scoped>
  .dialog-footer .el-button--primary {
    border-color: #ff8b02;
    background-color: #ff8b02;
  }

  .el-input.is-active .el-input__inner,
  .el-input__inner:focus {
    border-color: #ff8b02 !important;
    outline: 0;
  }

  .el-dialog__headerbtn:focus .el-dialog__close,
  .el-dialog__headerbtn:hover .el-dialog__close {
    color: #ff8b02 !;
  }
</style>