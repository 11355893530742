<template>
    <div id="footer">
        <div class="w_1180_f_height">
            <div id="f_logo">
                <a href="/">
                    <img  :src="footerList.site_logo_white">
                    </a>
                <!-- <span>创业小鸟-全球注册，全国通办</span> -->
            </div>
            <!--服务目录-->
            <dl v-for="(item,index) in filteredTopList" :key="index">
                <dt><router-link
                        :to="{path:'/company_register',query:{id:item.productList[0].id,category_id:item.productList[0].category_id}}">{{item.category_name}}</router-link>
                </dt>
                <dd v-for="(productItem,productIndex) in item.productList.slice(0,5)" :key="productIndex">
                    <router-link style="display: inline-block; width: 126px;
                    white-space: nowrap; text-overflow: ellipsis; overflow: hidden;"
                        :to="{path:'/company_register',query:{id:productItem.id,category_id:productItem.category_id}}"
                        class="">{{productItem.title}}</router-link>
                </dd>
            </dl>
            <dl>
                <dt><a href="/contact" class="">联系我们</a></dt>
                <dd v-for="(item,index) in footerList.contact_phone" :key="index"><a href="javascript:void(0);" class="" >{{item}}</a></dd>
              </dl>
            <dl>
                <dt><a href="/about" class="">关于我们 </a></dt>
                <dt><a href="/certificate" class="">公司资质</a></dt>
                <dt><a href="/join" class="">加入小鸟 </a></dt>
                <dt><a href="/contact" class="">联系我们 </a></dt>
                <dt><a href="/pay" class="">支付方式 </a></dt>
                <dt><a href="/agreement" class="">服务协议 </a></dt>
                <dt><a href="/privacy" class="">隐私协议 </a></dt>
        
              </dl>
              <dl>
                <img v-show="displayMenu === 'menu1'" style="width: 100%;" :src="footerList.mp_account_qr_code" alt="">
                <dd style="line-height: 36px;" @mouseover="toggleDisplay('menu1')"><a href="javascript:void(0);" class="">公众号</a></dd>
                <img  v-show="displayMenu === 'menu2'" style="width: 100%;" :src="footerList.mp_program_qr_code" alt="">
                <dd style="line-height: 36px;"  @mouseover="toggleDisplay('menu2')"><a href="javascript:void(0);" class="">小程序</a></dd>
                <img v-show="displayMenu === 'menu3'" style="width: 100%;" :src="footerList.video_code" alt="">
                <dd style="line-height: 36px;" @mouseover="toggleDisplay('menu3')"><a href="javascript:void(0);" class="">视频号</a></dd>
              </dl>
        </div>
        <div class="clearfix_footext1">
            <!--热门服务-->
            <div class="ul1" v-for="(item,index) in linkTopList" :key="index">
                <span>{{item.title}}</span>
                <ul v-for="(item2,index2) in item.linkList">
                    <li><a :href="item2.link">{{item2.content}} </a></li>
                </ul>
            </div>
        </div>
        <div  style="background-color: black; height: 43px;">
            <div class="w_1180_footext3">
                <p>
                 <span>{{footerList.copyright}} 版权所有</span>
                 <a href="http://beian.miit.gov.cn" target="_blank">{{footerList.icp_number}}</a>
                 <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44060402001306" target="_blank">
                     <img src="../../assets/img/ico13.png">{{footerList.network_security_number}}</a>
                 <a href="http://wssq.saic.gov.cn:9080/tmsve/agentInfo_getAgentDljg.xhtml" target="_blank">
                     <img src="../../assets/img/ico14.png"> 国家知识产权局备案代理机构</a>
                 <a href="http://wljg.gdgs.gov.cn/corpsrch.aspx?key=%E5%B9%BF%E4%B8%9C%E5%88%9B%E4%B8%9A%E5%B0%8F%E9%B8%9F%E4%BF%A1%E6%81%AF%E6%8A%80%E6%9C%AF%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8"
                     target="_blank">
                     <img src="../../assets/img/ico15.png"> 国家企业信用信息公示系统（广东）</a>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                // topList: [],
                displayMenu: 'menu1' ,
                productList: [],
                linkList: [],
                linkCategory:[]
            }
        },
        props:['footerList','topList'],
        computed: {
            filteredTopList() {
                return this.topList.map(item => ({
                    ...item,
                    productList: this.productList.filter(productItem => productItem.category_id === item.id).slice(0, 5)
                }))
            },
            linkTopList(){
                return this.linkCategory.map(item=>({
                    ...item,
                    linkList: this.linkList.filter(linkitem=>linkitem.link_id===item.id)
                }))
            }
        },
        updated() {
        },
        mounted() {
            this.getProduct()
            // this.getList()
            this.getLink()
            this.getLinkCategory()
        },
        methods: {
            async getProduct() {
                const res = await this.$request.get('product/getAll');
                if (res.data.code === 0) {
                    this.productList = res.data.data
                }
            },
            // async getList() {
            //     const res = await this.$request.get('productcategories/getPaginate');
            //     if (res.data.code === 0) {
            //         this.topList = res.data.data.data
            //         this.topList.reverse()
            //     }
            // },
            async getLink() {
                const res = await this.$request.post('link/getAll');
                if (res.data.code === 0) {
                    this.linkList = res.data.data
               
                }
            },
            async getLinkCategory(){
                const res = await this.$request.post('linkcategory/getAll');
                if (res.data.code===0) {
                    this.linkCategory=res.data.data
                }
            },
            toggleDisplay(menuId) {
            this.displayMenu = menuId;
        }
        },

    }
</script>
<style lang="scss" scoped>
    #footer {
        width: 100%;
        background: #ffffff;
        padding: 30px 0 0px 0;
    }

    .w_1180_f_height {
        width: 1180px;
        height: 280px;
        position: relative;
        margin: 0 auto;
        /* border-bottom: 1px solid #efefef; */
    }

    /* .w_1180_f_height::before {
        position: absolute;
        top: 30px;
        left: 305px;
        background: #efefef;
        width: 1px;
        height: 250px;
        content: ""
    } */

    .w_1180_f_height #f_logo {
        width: 220px;
        height: 274px;
        float: left;
    }

    .w_1180_f_height #f_logo a img{
        width: 100%;
    }

    .w_1180_f_height #f_logo span {
        line-height: 24px;
        color:#b0b0b0;
        width: 254px;
        display: block;
        text-indent: 26px;
        font-size: 18px;
    }

    .w_1180_f_height dl {
        width: 120px;
        float: left;
        text-align: center;
    }

    .w_1180_f_height dl a {
        font-size: 14px;
        color:#333333;
        padding: 6px;
        text-decoration: none;
    }

    .w_1180_f_height dt {
        font-weight: 700;
        line-height: 40px;
    }
    .w_1180_f_height dt a{
        font-size: 16px;
        padding: 0;
        padding-left: 6px;
    }

    .w_1180_f_height dl dd a:hover {
        transition: all .2s;
        background-color: #ff9631;
        color: #efefef;
    }

    .w_1180_f_height dd {
        line-height: 25px;
    }

    .w_1180_f_height dl .sideMenu2 {
        margin: 0 auto;
    }

    .w_1180_f_height dl .sideMenu2 h3 {
        font-weight: normal;
        color:#333333;
        font-size: 14px;
        line-height: 36px;
        cursor: pointer;
    }

    .w_1180_f_height dl .sideMenu2 ul li {
        list-style: none;
    }

    .clearfix_footext1 {
        width: 1180px;
        margin: 0 auto;
        border-bottom: 1px solid #efefef;
        padding: 15px 0;
        overflow: hidden;
    }

    .clearfix_footext1 .ul1 {
        width: 1180px;
        position: relative;
        padding-left: 130px;
        height: 35px;
        font-size: 13px;
        line-height: 35px;
    }

    .clearfix_footext1 .ul1 span {
        position: absolute;
        top: 0;
        left: 23px;
        color: #575757;
    }

    .clearfix_footext1 .ul1 ul {
        list-style: none;
    }

    .clearfix_footext1 .ul1 li {
        width: 116px;
        height: 35px;
        line-height: 35px;
        overflow: hidden;
        float: left;
    }

    .clearfix_footext1 .ul1 li a {
        text-decoration: none;
        font-size: 14px;
        color:#333333;
    }

    .w_1180_footext2 {
        margin: 0 auto;
        width: 1180px;
        line-height: 92px;
        text-indent: 23px;
    }

    .w_1180_footext2 a {
        text-decoration: none;
        padding-right: 23px;
        font-size: 15px;
        color:#b0b0b0;
    }

    .w_1180_footext2 img {
        width: auto !important;
        position: relative;
        top: -3px;
        margin-left: 40px;
        vertical-align: middle;
    }

    .w_1180_footext2 span {
        color:#b0b0b0;
        margin-left: 10px;
    }

    .w_1180_footext3 {
        width: 1180px;
        margin: 0 auto;
    }

    

    /* .w_1180_footext3>p, */
    .w_1180_footext3>p>a {
        color: #FFFFFF;
    font-size: 14px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    margin-left: 10px;
    }
    .w_1180_footext3>p{
        display: flex;
        align-items: center;
        line-height: 43px;
        height: 100%;
        text-indent: 23px;
        color: #FFFFFF;
        font-size: 14px;
        white-space: nowrap;
    }

    .w_1180_footext3>p a img {
        width: auto !important;
        margin-left: 10px;
        vertical-align: bottom;
    }

    .service-container2>.item {
        margin-right: 5px;
    }
</style>