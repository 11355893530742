<template>
  <div id="app">
    <Top :topList="topList" :footerList="siteList" />
    <div v-if="$route.path!=='/'" class="app-fixed-tool text-center">
      <div class="p-v-10 item" id="miniProgramRightItem" @mouseover="Show" @mouseout="Show">
        <i class="f-23 icon icon-mini_program"></i>
        <div class="text f-11">小程序</div>
      </div>
      <div class="p-v-10 item" @click="openDialog">
        <i class="f-23 icon icon-trademark"></i>
        <div class="text f-11">商标咨询</div>
      </div>
      <div class="p-v-10 item" @click="openDialog">
        <i class="f-23 icon icon-company"></i>
        <div class="text f-11">公司注册</div>
      </div>
      <div class="p-v-10 item" @click="openDialog">
        <i class="f-23 icon icon-qualification"></i>
        <div class="text f-11">资质咨询</div>
      </div>
      <div class="p-v-10 item" @click="openDialog">
        <i class="f-23 icon icon-claim"></i>
        <div class="text f-11">认证咨询</div>
      </div>
      <div class="p-v-10 item toTop" @click="scrollToTop">
        <i class="f-23 icon icon-to_top"></i>
        <div class="text f-11">置顶</div>
      </div>
    </div>
    <!--小程序二维码-->
    <div class="ant-popover ant-popover-placement-left" id="miniProgramQrCode"
      style="width: max-content;top:unset;left:unset;position: fixed;right: 80px; bottom: 360px;"
      :style="{ display: isShow?'block':'none'}">
      <div class="ant-popover-content">
        <div class="ant-popover-arrow"></div>
        <div role="tooltip" class="ant-popover-inner">
          <div>
            <div class="ant-popover-inner-content">
              <div data-v-327b80f5="">
                <img data-v-327b80f5="" src="@/assets/img/d957cbc6f8397067e21eb2fa585671af.png" alt="mini-program"
                  class="mini-program">
                <div data-v-327b80f5="" class="text-center m-t-15">创业小鸟-全球注册，全国通办</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-view :footerList="siteList" :topList="topList" />
    <!-- 弹窗内容 -->
    <GlobalDialog ref="globalDialog" />
    <!-- 侧边栏 -->
    <div id="sidebar" v-if="$route.path==='/'">
      <div @mouseover="showChengxu=true" @mouseleave="showChengxu=false" class="hover-revert re1">
        <div class="si"><i class="si1"></i></div>
        <div class="text">小程序</div>
        <div v-show="showChengxu" class="tooltip">
          <div class="tooltip_content">
            <img :src="siteList.mp_program_qr_code">
            <div>创业小鸟小程序</div>
            <div class="tooltip_arrow"></div>
          </div>
        </div>
      </div>
      <div @mouseover="showPublic=true" @mouseleave="showPublic=false" class="hover-revert re2">
        <div class="si"><i class="si2"></i></div>
        <div class="text">公众号</div>
        <div v-show="showPublic" class="tooltip">
          <div class="tooltip_content">
            <img :src="siteList.mp_account_qr_code">
            <div>创业小鸟公众号</div>
            <div class="tooltip_arrow"></div>
          </div>
        </div>
      </div>
      <div class="hover-revert re3">
        <a href="/vip.html">
          <div class="si"><i class="si3"></i></div>
          <div class="text">会员</div>
        </a>
      </div>
      <div @mouseover="showKefu=true" @mouseleave="showKefu=false" class="hover-revert re4">
        <div class="si"><i class="si4"></i></div>
        <div class="text">微信</div>
        <div v-show="showKefu" class="tooltip">
          <div class="tooltip_content">
            <img :src="siteList.weibo_qr_code">
            <div>微信扫码添加专属客服</div>
            <div class="tooltip_arrow"></div>
          </div>
        </div>
      </div>
      <div class="hover-revert sfeedback re5">
        <div class="si"><i class="si5"></i></div>
        <div class="text">反馈</div>
      </div>
      <div @mouseover="xnKefu=true" @mouseleave="xnKefu=false" class="hover-revert re6">
        <div class="si"><i class="si6"></i></div>
        <div class="text">立即咨询</div>
        <div v-show="xnKefu" class="tooltip">
          <div class="tooltip_content">
            <img :src="siteList.weibo_qr_code">
            <div>创业小鸟客服</div>
            <div class="tooltip_arrow"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Top from './components/top/index.vue';
  import AppDialog from "./components/appDialog";
  export default {
    components: {
      Top,
      AppDialog
    },
    data() {
      return {
        showChengxu:false,
        showPublic:false,
        showKefu:false,
        xnKefu:false,
        dialogVisible: false,
        isShow: false,
        siteList: [],
        topList: []
      }
    },
    created() {
      this.getSite()
      this.getList()
    },
    metaInfo() {
      return {
        meta: [
          {
            name: 'keywords',
            content: this.siteList ? this.siteList.seo_keywords : ' ',
          },
          {
            name: 'description',
            content: this.siteList ? this.siteList.seo_description : ' ',
          },
        ],
      }
    },
    watch: {
      siteList: {
        handler(newVal) {
          if (newVal.length > 0) {
            this.$nextTick(() => {
              this.updateMetaInfo();
            });
          }
        },
        immediate: true,
      },
    },
    methods: {
      updateMetaInfo() {
        this.$metaInfo.meta = [
          {
            name: 'keywords',
            content: this.siteList.seo_keywords,
          },
          {
            name: 'description',
            content: this.siteList.seo_description,
          },
        ];
      },
      scrollToTop() {
        // 使用$refs获取DOM元素的引用
        const element = this.$refs.topElement;

        // 使用window对象的scrollTo方法来滚动到元素的顶部
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // 可选的，定义滚动行为，'smooth' 为平滑滚动
        });
      },
      openDialog() {
        this.$refs.globalDialog.dialogVisible = true;
      },
      Show() {
        this.isShow = !this.isShow
      },
      async getSite() {
        const res = await this.$request.post('seo/getPaginate')
        if (res.data.code === 0) {
          this.siteList = res.data.data.data[0]
          this.siteList.contact_phone = this.siteList.contact_phone.split('、')
        }
      },
      async getList() {
        const res = await this.$request.get('productcategories/getPaginate');
        if (res.data.code === 0) {
          this.topList = res.data.data.data
          this.topList.reverse()
        }
      },
    },
  }
</script>

<style lang="scss">
  #sidebar {
    position: fixed;
    z-index: 9999;
    text-align: center;
    right: 10px;
    top: 50%;
    margin-top: -220px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }

  #sidebar .hover-revert {
    color: #7a808f;
    padding: 5px;
    background-color: #fff;
    cursor: pointer;
    position: relative;
  }

  #sidebar .tooltip {
    position: absolute;
    left: -190px;
    top: -50px;
    background-color: #fff;
    font-size: 12px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    /* display: none; */
  }

  #sidebar .hover-revert:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  #sidebar .hover-revert .si {
    padding: 10px 10px 5px 10px;
    text-align: center;
  }

  #sidebar .hover-revert .si i{
    width: 24px;
    height: 24px;
    display: inline-block;
    background-image: url('../src/assets/img/si.png');
    background-repeat: no-repeat;
  }

  #sidebar .hover-revert .si1{
    background-position: 0 0;
  }
  #sidebar .re1:hover{
    background-color: #ff9631;
  }
  #sidebar .re1:hover .text{
    color: #FFFFFF;
  }
  #sidebar .re1:hover .si1{
    background-position: -24px 0;
  }
  #sidebar .hover-revert .si2 {
    background-position: 0 -24px;
  }
  #sidebar .re2:hover{
    background-color: #ff9631;
  }
  #sidebar .re2:hover .text {
    color: #FFFFFF;
  }
  #sidebar .re2:hover .si2 {
    background-position: -24px -24px;
  }
  #sidebar .hover-revert .si3 {
    background-position: 0 -48px;
  }
  #sidebar .re3:hover{
    background-color: #ff9631;
  }
  #sidebar .re3:hover .text {
    color: #FFFFFF;
  }
  #sidebar .re3:hover .si3 {
    background-position: -24px -48px;
  }
  #sidebar .hover-revert .si4 {
    background-position: 0 -72px;
  }
  #sidebar .re4:hover{
    background-color: #ff9631;
  }
  #sidebar .re4:hover .text {
    color: #FFFFFF;
  }
  #sidebar .re4:hover .si4{
    background-position: -24px -72px;
  }
  #sidebar .hover-revert .si5 {
    background-position: 0 -96px;
  }
  #sidebar .re5:hover{
    background-color: #ff9631;
  }
  #sidebar .re5:hover .text {
    color: #FFFFFF;
  }
  #sidebar .re5:hover .si5 {
    background-position: -24px -96px;
  }
  #sidebar .hover-revert .si6 {
    background-position: 0 -120px;
 }
 #sidebar .re6:hover{
    background-color: #ff9631;
  }
  #sidebar .re6:hover .text {
    color: #FFFFFF;
  }
 #sidebar .re6:hover .si6 {
    background-position: -24px -120px;
 }

  #sidebar .hover-revert .text {
    font-size: 12px;
  }

  #sidebar .tooltip .tooltip_content{
    padding: 12px 16px;
    color: #333;
    text-align: center;
  }
  #sidebar .tooltip .tooltip_content img{
    width: 140px;
    height: 140px;
    margin-bottom: 10px;
  }
  #sidebar .tooltip .tooltip_arrow{
    position: absolute;
    display: block;
    width: 8.48528137px;
    height: 8.48528137px;
    background: transparent;
    border-style: solid;
    border-width: 4.24264069px;
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg);
    right: -1px;
    border-color: #fff #fff transparent transparent;
    -webkit-box-shadow: 3px -3px 7px rgba(0, 0, 0, .07);
    box-shadow: 3px -3px 7px rgba(0, 0, 0, .07);
  }
  #sidebar .hover-revert:last-child {
    border-bottom-left-radius: 5px;
  }
</style>