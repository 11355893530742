import Vue from 'vue'
import App from './App.vue'
import router from './libs/router'
import store from './store'
import GlobalDialog from './components/appDialog.vue';
import './assets/css/public.css'
import './assets/css/serve.css'
import './assets/css/reset.css'
// import './assets/css/index.css'
import './assets/css/type-list-2019.css'
import './assets/css/serve_view.css'
//全局post方法
import request from './libs/request'
Vue.prototype.$request=request;
Vue.config.productionTip = false
//引入element
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

//使用vue-meta
import VueMeta from 'vue-meta';
Vue.use(VueMeta);

//引入Dompurify库
import DOMpurify from 'dompurify';
Vue.prototype.$sanitize=function (html) {
  return DOMpurify.sanitize(html);
}

Vue.component('GlobalDialog', GlobalDialog);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
